.CenteredTitle {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-top: 10vh;
    text-align: center;
}

.AboutMeSubtitle {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: -10px;
}

.AboutMeTitle {
    font-size: 35px;
    font-weight: 400,
}

.FullPageResumeContainer {
    height: fit-content;
    display: flex;
    justify-content: center;
    align-content: center;
    background-color: white;
}