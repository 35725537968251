.SocialMediaButtons {
    border-radius: 50%;
    background-color: gray;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 7px;
    margin-right: 7px;
    border: solid;
    border-color: white;
}

.SocialMediaButtons:hover {
    border-radius: 50%;
    background-color: #b8b8b8;
    cursor: pointer;
}

.SocialMediaButtons:active {
    background-color: #57b9bd;
}

.NormalButtons {
    border-radius: 50%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 7px;
    margin-right: 7px;
    border: solid;
    border-color: darkgreen;
}

.NormalButtons:hover {
    background-color: #b8b8b8;
}