.ProfileCircleContainer {
  margin: auto;
  border-radius: 50%;
  border-width: 10px;
  width: 150px;
  height: 150px;
  background-color: white;
}

.ProfileCircle {
  margin-top: 5px;
  margin-left: 5px;
  border-radius: 50%;
  width: 140px;
  height: 140px;
  background-color: black;
}

.ProfileCircle:hover {
  background-color: #FFCA2C;
}

.ProfileCircleContainer:hover {
  cursor: pointer;
}