.AnimatedButtonText {
    color: white;
    font-weight: 700;
    font-size: large;
    display: block;
    margin-bottom: 0px;
}

.progress {
    background-color: black;
}

.AnimatedButtonText:hover {
    font-weight: 700;
    color: lightgray;
}

.AnimatedButtonContainer {
    margin-top: 10px;
    margin-bottom: 20px;
    max-width: 100px;
}

.AnimatedButtonContainer:hover {
    cursor: pointer;
}

.AnimatedButtonTextSelected {
    font-weight: 700;
    font-size: x-large;
    color: white;
    margin-bottom: 0px;
}

.AnimatedButtonProgress {
    width: 30px;
    height: 100px;
}

.AnimatedButtonProgressSelected {
    width: 50px;
    height: 1px;
}