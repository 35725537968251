.ContactCardContainer {
  flex: 1;
  display: block;
  margin-left: 15px;
  margin-right: 15px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  border-radius: 10px;
  flex-basis: 100px;
  text-align: center;
  justify-content: center;
  align-content: center;
}

.Icon {
  height: 40px;
  width: 40px;
  margin-top: 20px;
  margin-bottom: 0px;
}

.CardTitle {
  font-weight: 500;
  font-size: 18px;
}