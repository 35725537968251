.FullPageContainer {
  background-image: linear-gradient(220deg, rgb(88, 214, 88) 0%, #456751 100%);
  justify-content: center;
  align-content: center;
}

.AnimatedContactmeButtonContainer {
  position: sticky;
  top: 90%;
  left: 87%;
  width: 150px;
  z-index: 999;
}

/* background-image: linear-gradient(240deg, #129a17 0%, #3bdc73 100%);
  // */

/* background-image: linear-gradient(240deg, black 0%, #02481b 100%); */

/* background-image: linear-gradient(220deg, rgb(1, 109, 1) 0%, #b0d0bb 100%); */