.FullPageAboutContainer {
  background-color: white;
  z-index: 99;
  height: fit-content;
  justify-content: center;
  align-content: center;
}

.FullPageAboutContentContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.FullPageAboutPicture {
  display: block;
  height: 500px;
  margin: 0 10vh 10vh 10vh;
}

.AboutCardIcon {
  height: 50px;
  width: 50px;
  margin-top: 35px;
  margin-bottom: 15px;
}

.FullPageAboutHeaderContainer {
  z-index: 44;
  text-align: center;
  padding-top: 10vh;
  padding-bottom: 30px;
}

.FullPageAboutContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  flex-basis: 30%;
  margin: 0px 20px 10px 20px;
}

.AboutCardsContainer {
  flex: 1;
  margin-left: 15px;
  margin-right: 15px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
  flex-basis: 100px;
  text-align: center;
}

.CardTitle {
  font-weight: 500;
  font-size: 20px;
  padding-top: 10px;
}

.AboutMeSubtitle {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: -10px;
}

.AboutMeTitle {
  font-size: 35px;
  font-weight: 400,
}