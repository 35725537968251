.AnimatedContactButtonContainer {
    background-color: greenyellow;
    width: fit-content;

    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    transform: scale(1);
    animation: pulse 2s infinite;
}

.AnimatedContactButtonContainer:hover {
    cursor: pointer;
}

.AnimatedContactButtonContainer p {
    color: black;
    font-size: large;
    font-weight: 500;
    padding: 10px 25px 10px 25px;
}

@keyframes pulse {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}

/* 
.AnimatedContactButtonContainer2 {
    border-width: 10px;
    border-spacing: 10px;
    border-color: red;
    border: solid;
    background-color: blue;
    width: 200px;
    height: 280px;
    transition: width 1s, height 1s;
}

.staticButtonStyle {
    border-width: 10px;
    border-spacing: 100px;
    border-color: red;
    border: solid;
    background-color: purple;
    width: 100px;
    height: 180px;
    transition: width 1s, height 1s;
} */