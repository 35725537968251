.FullPageSkillsContainer {
    height: auto;
    min-height: 80vh;
    display: flex;
    justify-content: center;
    align-content: center;
    background-image: linear-gradient(150deg, rgb(255, 237, 184), 0%, rgb(255, 202, 44) 100%)
}

.GlassContainer {
    width: 90%;
    height: 80%;
    padding: 10vh 4vh 10vh 4vh;
    margin: auto;
    align-content: center;
    font-family: Arial;
    text-align: left;
    background: #c5c6c7;
    background: linear-gradient(to right bottom, rgba(230, 228, 228, 0.8), rgba(244, 226, 226, 0.3));
    /* border-radius: 1rem; */
    z-index: 1;
    -webkit-backdrop-filter: blur(0.3rem);
    backdrop-filter: blur(0.3rem);
}

.FullPageSkillstHeaderContainer {
    z-index: 44;
    text-align: center;
    padding-bottom: 7vh;
}


.CenteredTitle {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 0px 20px 0px 20px;
}

.AboutMeSubtitle {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: -10px;
}

.AboutMeTitle {
    font-size: 35px;
    font-weight: 400,
}