.SidePanelContainer {
  /* background-color: black; */
  padding-top: 30px;
  position: fixed;
  height: 100%;
  width: 250px;
  left: 0;
  top: 0;
  justify-content: center;
  align-content: center;
}

.VideoBackground {
  width: 250px;
  height: 100%;
  z-index: 3;
  top: 0;
  left: 0;
  object-fit: cover;
  object-position: 0%;
  position: fixed;
}

.SidePanelText {
  color: white;
  text-align: center;
  margin-top: -14px;
  display: block;
  padding-bottom: 30px;
}

.SidePanelHeader {
  color: white;
  text-align: center;
  font-size: 30px;
  padding-top: 15px;
  display: block;
}

.AnimatedButtonGroup {
  justify-content: center;
  margin-left: 45px;
}

.SocialMediaButtonGroup {
  margin-top: -30px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}