.FullPageHomeContainer {
    height: 915px;
    margin-top: -60px;
    justify-content: center;
    align-content: center;
}

.HomeIcon {
    width: 50px;
    height: 50px;
}